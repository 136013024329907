.navbar {
    width: 100%;
    height: 4vh;
    background-color: rgb(18, 18, 18);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2.5rem 3rem 2.5rem 3rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0);
    z-index: 1;
}

.brand-icons {
    font-size: 2rem;
    padding: 0.8rem;
}

.solid-icons {
    font-size: 1.2rem;
    padding: 0 0.3rem 0 3rem;
}

.navbar-text {
    font-size: 1.1rem;
}

.navbar > div > a {
    color: var(--color-white)
}

.navbar > div > a:hover {
    color: var(--color-primary)
}

/* Media Queries (Mobile) */

@media screen and (max-width: 550px) {
    .navbar {
        padding: 1rem;
        height: 6vh;
        justify-content: center;
    }
    
    .navbar-menu {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .brand-icons {
        display: none;
    }

    .solid-icons {
        font-size: 1rem;
        padding: 0;
    }

    .navbar-text {
        font-size: 1rem;
        padding-left: 0.3rem;
    }
}

/* Media Queries (Tablets) */

@media screen and (min-width: 551px) and (max-width: 1250px) {
    .navbar {
        justify-content: center;
    }
}