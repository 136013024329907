.contact-container {
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 10%;
}

.contact-options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact-option {
    background: var(--color-primary-variant);
    padding: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact-option:hover {
    background: transparent;
    border-image: var(--color-primary) 1;
}

.contact-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; 
}

.contact-option > a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

.contact-option > h5 {
    color: var(--color-light);
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    background: transparent;
    border: 2px solid;
    border-image: var(--color-primary) 1;
    resize: none;
    color: var(--color-white);
}

textarea {
    height: 100%;
}
/* Media Queries (Tablets) */

@media screen and (min-width: 551px) and (max-width: 1250px) {
   #contact {
        padding-top: 7rem;
   }
   
    .contact-container {
        grid-template-columns: 1fr;
        gap: 2rem;
   }
}
  
  /* Media Queries (Phones) */
  
  @media screen and (max-width: 550px) {
    #contact {
        padding-top: 3rem;
   }

    .contact-container {
        width: var(--container-width-sm);
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}