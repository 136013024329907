footer {
    background: rgba(0, 0, 0, 0.30);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

.footer-name {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-icons {
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.footer-icons > a {
    color: var(--color-white);
    font-size: 2.5rem;
}

.footer-icons > a:hover {
    color: var(--color-primary);
}

/* Media Queries (Phones) */

@media screen and (max-width: 550px) {
    .links {
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer-icons {
        margin-bottom: 2.6rem;
    }
  }