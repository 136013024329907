.about-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}

#about {
    padding-top: 6rem;
}

#about > h2 {
    font-size: 2rem;
    margin-top: 0.2rem;
}

#about > h5 {
    font-size: 1.2rem;
    margin: 0;
}

.about-container > p {
    margin: 0;
    color: var(--color-light);
}

.about-me {
    display: flex;
    align-items: center;
    height: 28rem;
    aspect-ratio: 5/7;
    border-radius: 2rem;
    background: var(--color-primary)
}

.about-img {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
    height: 100%;
}

.about-img:hover {
    transform: rotate(0);
}

.about-img > img {
    height: 100%;
}

.skills-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 5rem;
    grid-column: 1 / span 2;
}

.individual-skill {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 2px solid;
    border-image: var(--color-primary) 1;
    width: 8rem;
}

.individual-skill > img {
    height: auto;
    max-width: 100%;
}

.contact-button-div {
    grid-column: 1 / span 2;
}

/* Media Queries (Phones) */

@media screen and (max-width: 550px) {
    .about-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .skills-div {
        gap: 1rem;
    }
    
    .individual-skill {
        margin-bottom: 1rem;
        padding: 1rem;
        width: 7rem;
    }
}