html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: 'Open Sans', sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

:root {
  --color-bg: #1a1a1a;
  --color-primary: linear-gradient(135deg, #007BFF, #FF7F50);
  --color-primary-variant: #0066CC;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%; 

  --transition: all 400ms ease;
}

/* General Styling */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 4rem;
}

section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  text-align: center;
  background: var(--color-primary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: var(--color-white);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-white);
  transition: var(--transition);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  color: var(--color-primary);
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-white);
  padding: 0.75rem 1.2rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  background: var(--color-primary);
}

.btn:hover {
  background: linear-gradient(to left, #007BFF, #FF7F50);
  color: var(--color-white);
  border-color: transparent;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* Media Queries (Tablets) */

@media screen and (min-width: 551px) and (max-width: 1250px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* Media Queries (Phones) */

@media screen and (max-width: 550px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}