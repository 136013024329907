header {
    padding: 10rem 0 15rem 0;
    overflow: hidden;
}

.header-container {
    text-align: center;
    height: 100%;
    position: relative;
}

.container > h1 {
    font-size: 2.5rem;
    margin: 0;
}

.container > h5 {
    font-size: 1.2rem;
    margin: 0;
}

/* Img Effect */

.img-container {
    display: grid;
    place-items: center;
    margin: 6rem 2rem 0 2rem;
}

.img-group, .img {
    width: 23rem;
    aspect-ratio: 5/7;
}

.img {
    position: absolute;
    transition: transform 800ms cubic-bezier(.05, .43, .25, .95);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem;
}

.img > img {
    min-width: 23rem;
    aspect-ratio: 5/7;
}

.big-img1 {
    transform: translateX(-10%) rotate(-1deg);
}

.big-img2 {
    transform: rotate(2deg);
}

.big-img3 {
    transform: translateX(-6%) rotate(-3deg);
}

.big-img4 {
    transform: translate(10%, 3%) rotate(5deg);
}


.big-img1-effect {
    transform: translate(-95%, 16%) rotate(-24deg);
}

.big-img2-effect {
    transform: translate(-35%, 5%) rotate(-8deg);
}

.big-img3-effect {
    transform: translate(35%, 5%) rotate(8deg);
}

.big-img4-effect {
    transform: translate(95%, 16%) rotate(24deg);
}

/* Effect ends here */

/* Media Queries (Phones) */

@media screen and (max-width: 550px) {
    header {
        padding: 7rem 0 0 0;
    }

    .img-container {
        margin: 2rem auto;
        display: flex;
        justify-content: center;
    }

    .img-group, .img, .img > img {
        width: 22rem;
    }

    .big-img4 {
        position: relative;
        transform: translateX(0);
    }

    .big-img1, 
    .big-img2, 
    .big-img3 {
        display: none;
    }
}

/* Media Queries (Tablets/Small screens) */

@media screen and (min-width: 551px) and (max-width: 1250px) {
    header {
        padding: 9rem 0 0 0;
    }
    
    .img-container {
        margin: 2rem auto;
        display: flex;
        justify-content: center;
    }

    .img-group, .img, .img > img {
        width: 28rem;
    }

    .big-img4 {
        position: relative;
        transform: translateX(0);
    }

    .big-img1, 
    .big-img2, 
    .big-img3 {
        display: none;
    }
}

