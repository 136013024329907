#portfolio {
    padding: 6rem 0 4rem 0;
}

#portfolio > h2 {
    font-size: 2rem;
    margin: 0.2rem 0 0.5rem 0;
}

#portfolio > h5 {
    font-size: 1rem;
    margin: 0;
}

/* Title/Heading */

.more-projects-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 2rem 0;
}

.more-projects-section {
    color: var(--color-light);
    font-size: 1rem;
}

.more-projects-section:hover {
    color: var(--color-white);
}

.more-projects-text {
    margin-left: 0.3rem;
}

/* Projects Section */

.portfolio-container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.projects-container {
    display: flex;
    align-items: center;
}

.projects-container:nth-child(odd) {
    align-self: flex-start;
}

.projects-container:nth-child(even) {
    align-self: flex-end;
}

.project-img {
    background: var(--color-primary);
    width: 90%;
    aspect-ratio: 16/9;
}

.project-img img {
    height: 100%;
}

.img-left {
    padding: 3rem 4rem 3rem 0;
}

.img-right {
    padding: 3rem 0 3rem 4rem;
}

.project-main-content {
    text-align: center;
}

.main-left {
    margin-left: 5rem;
}

.main-right {
    margin-right: 5rem;
}

.project-text {
    text-align: left;
}

.project-text > h3 {
    font-size: 2rem;
    margin: 0;
}

.project-text > p {
    font-size: 1.1rem;
    color: var(--color-light)
}

.project-btns {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Media Queries (Tablets) */

@media screen and (min-width: 551px) and (max-width: 1250px) {
    .project-text > h3 {
        font-size: 1.8rem;
    }
    
    .project-text > p {
        font-size: 1rem;
    }
    
    .project-btns {
        flex-direction: row;
    }
}
  
  /* Media Queries (Phones) */
  
@media screen and (max-width: 550px) {
    .portfolio-container {
        gap: 3rem;
    }

    .project-img {
        width: 100%;
    }

    .img-left {
        padding: 1rem 2rem 1rem 0;
    }
    
    .img-right {
        padding: 1rem 0 1rem 2rem;
    }

    .main-left {
        margin-left: 1rem;
    }
    
    .main-right {
        margin-right: 1rem;
    }

    .project-text > h3 {
        font-size: 1.2rem;
        margin: 0;
    }
    
    .project-text > p {
        font-size: 0.7rem;
        color: var(--color-light)
    }

    .project-btns {
        flex-direction: row;
    }
    
    .project-btns > a {
        font-size: 0.7rem;
        padding: 0.5rem 0.75rem;
    }
}